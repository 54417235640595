<template>
  <Dialog
    header="Add New Conference"
    :modal="true"
    v-model:visible="displayBasic"
    :style="{ width: '50vw' }"
  >
    <label class="p-d-block p-my-2" for="conference">Conference Name</label>
    <Dropdown
      class="p-inputtext-lg"
      v-bind:class="{ 'p-invalid': selectedConferenceInvalid }"
      v-model="selectedConference"
      :options="conferences"
      style="width: 100%;"
      optionLabel="name"
      optionValue="code"
      placeholder="Select a Conference"
    />
    <label class="p-d-block p-my-2" for="range">Date</label>
    <Calendar
      id="range"
      class="p-inputtext-lg"
      style="width: 100%;"
      v-model="dateRange"
      v-bind:class="{ 'p-invalid': dateRangeInvalid }"
      placeholder="Select Days"
      selectionMode="range"
      dateFormat="DD, MM, dd, yy"
      :manualInput="false"
    />
    <label class="p-d-block p-my-2" for="hotel">Hotel</label>

    <InputText
      type="text"
      v-model="hotelName"
      v-bind:class="{ 'p-invalid': hotelNameInvalid }"
      name="hotel"
      class="p-inputtext-lg"
      placeholder="Hotel Name"
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2" for="city">City</label>

    <InputText
      type="text"
      v-model="cityName"
      v-bind:class="{ 'p-invalid': cityNameInvalid }"
      name="city"
      class="p-inputtext-lg"
      placeholder="City Name"
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2" for="state">State</label>

    <InputText
      type="text"
      name="state"
      v-model="stateName"
      v-bind:class="{ 'p-invalid': stateNameInvalid }"
      class="p-inputtext-lg"
      placeholder="State Name"
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2">Active</label>

    <InputSwitch v-model="confActive" />

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        @click="closeBasic"
        class="p-button-text"
      />
      <Button
        label="Add"
        icon="pi pi-check"
        @click="useAddConference"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { ref, watch } from "vue";
import SaveDocument from "../composables/SaveDocument";

export default {
  name: "AddConferenceModal",
  props: ["showModal"],
  emits: ["modalVisibility"],
  setup(props, { emit }) {
    const { addDocument, newDoc, addDocError } = SaveDocument();
    const selectedConferenceInvalid = ref(false);
    const hotelNameInvalid = ref(false);
    const cityNameInvalid = ref(false);
    const stateNameInvalid = ref(false);
    const dateRangeInvalid = ref(false);
    const formFail = ref(true);
    const checkForm = () => {
      console.log(
        selectedConference.value,

        hotelName.value,
        cityName.value,

        stateName.value,

        dateRange.value
      );
      if (selectedConference.value == null) {
        selectedConferenceInvalid.value = true;
      } else {
        selectedConferenceInvalid.value = false;
      }
      if (hotelName.value == null) {
        hotelNameInvalid.value = true;
      } else {
        hotelNameInvalid.value = false;
      }
      if (cityName.value == null) {
        cityNameInvalid.value = true;
      } else {
        cityNameInvalid.value = false;
      }
      if (stateName.value == null) {
        stateNameInvalid.value = true;
      } else {
        stateNameInvalid.value = false;
      }
      if (dateRange.value == null) {
        dateRangeInvalid.value = true;
      } else {
        dateRangeInvalid.value = false;
      }
      if (
        selectedConference.value == null ||
        hotelName.value == null ||
        cityName.value == null ||
        stateName.value == null ||
        dateRange.value == null
      ) {
        console.log("its going to fail");
        formFail.value = true;
        return true;
      } else {
        formFail.value = false;
        return false;
      }
    };
    console.log(checkForm);

    const useAddConference = async () => {
      checkForm();
      console.log(hotelNameInvalid.value);
      console.log(selectedConference.value);
      console.log(formFail.value);
      if (formFail.value) {
        console.log("there was an error, returning");
        return;
      } else {
        const displayDate = document.querySelector("#range");
        console.log(displayDate.value);
        await addDocument("conference", {
          hotel: `${hotelName.value}`,
          city: `${cityName.value}`,
          state: `${stateName.value}`,
          date: `${dateRange.value}`,
          displayDate: displayDate.value,
          year: displayDate.value.split(" ")[3],
          conferenceName: `${selectedConference.value}`,
          active: confActive.value,
        });
        if (!addDocError.value) {
          console.log("it works", newDoc.value);
          displayBasic.value = false;
        }
      }
    };
    const confActive = ref(true);
    const hotelName = ref(null);
    const cityName = ref(null);
    const stateName = ref(null);
    const test = () => {
      console.log(props.showModal);
    };
    const displayBasic = ref(false);

    const openBasic = () => {
      displayBasic.value = true;
    };
    const closeBasic = () => {
      console.log("start emit...");
      emit("modalVisibility", { status: false });

      displayBasic.value = false;
    };

    watch(
      () => props.showModal,
      (first, second) => {
        console.log(
          "Watch props.showModal function called with args:",
          first,
          second
        );
        displayBasic.value = true;
      }
    );

    const dateRange = ref(null);
    const conferences = [
      { name: "MD Expo", code: "MD Expo" },
      { name: "ICE", code: "ICE" },
      { name: "OR Today Live", code: "OR Today Live" },
      { name: "Periop ConneCT", code: "Periop ConneCT" },
      { name: "HTM Mixer", code: "HTM Mixer" },
      { name: "Southeast Councils of periOperative Nurses", code: "Southeast Councils of periOperative Nurses" },
    ];

    const selectedConference = ref(null);

    return {
      confActive,
      hotelName,
      cityName,
      stateName,
      addDocError,
      displayBasic,
      openBasic,
      closeBasic,
      dateRange,
      conferences,
      selectedConference,
      test,
      useAddConference,
      selectedConferenceInvalid,
      hotelNameInvalid,
      cityNameInvalid,
      stateNameInvalid,
      dateRangeInvalid,
    };
  },
};
</script>

<style></style>
